import React from 'react'
import { graphql } from 'gatsby'

// import Meta from '../components/Meta.js'
import Layout from '../components/Layout.js'

export default ({ data, location }) => {
  return (
    <Layout location={location} fullScreen={true}>
      <div className="container mt-5">
        <h2>{data.pageSettings.data.impressum_headline.text}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: data.pageSettings.data.impressum_text.html }}
        />
      </div>
      <div id="datenschutz" className="container my-5">
        <h2>{data.pageSettings.data.datenschutz_headline.text}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: data.pageSettings.data.datenschutz_text.html,
          }}
        />
      </div>
    </Layout>
  )
}

export const impressumQuery = graphql`
  query impressumQuery {
    pageSettings: prismicPageSettings {
      data {
        impressum_headline {
          text
        }
        impressum_text {
          text
          html
        }
        datenschutz_headline {
          text
        }
        datenschutz_text {
          text
          html
        }
      }
    }
  }
`
